h2 {
		font-size: 20px!important; 
		margin-bottom:10px;
		line-height: 1.4;
	}
	ul {
	    list-style-position: inside;
	}
	.tupper {
	    text-transform: uppercase!important;
	}
	.imgleft {
	    padding-right: 20px;
	    padding-bottom: 15px;
	}
	.pagination > li > a{
        color: @body-color;
        background-color: #ffffff;
    }
    .pagination > li > a:hover {
        color: @theme-color;
    }
    
    .list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
        background-color: @theme-color;
        border-color: @theme-color;
    }
    .list-group-item.active > .badge, .nav-pills > .active > a > .badge {
        color: @theme-color;
    }
    .tabs-bordered .tab-container {
        background-color: #fff;
    }
    ul.tab-nav li a {
        font-weight: normal;
        
    }
    
/*#slider {
    height:50px!important;
    
    .swiper_wrapper .swiper-slide{
        background-position: bottom left!important;    
    }
}*/
#oferta {
	color:#fff;
	background-color: #8d8080;
	#oferta1, #oferta2, #oferta3 {
		background: url('gfx/bg-oferta1.png') repeat-x bottom center;
		//padding:0 10px;
	}
	#oferta2 {
		background-image: url('gfx/bg-oferta2.png');
	}
	#oferta3 {
		background-image: url('gfx/bg-oferta3.png');
	}
	h2 {
		color:#fff;
	//	font-size: 20px; 
		margin-bottom:10px;
	}
	p {
		margin-bottom:15px;	
	}
	.vac {
		height: auto;
		padding:25px 0 35px 0;
		margin: 0 auto ;
		width:440px;
		display: block;
	}
}

#katalogi-nowosci {
	background: url('gfx/bg-kat-now.png') repeat-y top center;
	
	h2 {
	//	font-size: 20px; 
		margin-bottom:10px;
	}
	
	#katalogi {
		background-color: @bg-color3;
		padding:25px 15px 30px 15px;
		
		p {
			margin-bottom:10px;
		}
	}
	#nowosci {
		background-color: @bg-color2;
		padding:25px 15px 30px 15px;
		
		#box-nowosci {
			background: #fff;
			padding:30px;
			border: 1px solid #d8d3d3;
			
		 	.owl-nav .owl-next, .owl-nav .owl-prev {
			    margin-top: -19px;
			}
			
			.wrap-nowosci {
				width:200px;
			}
			
			.img {
				width:75px;
				height:115px;
				float: left;
			}
			.desc {
				padding-left:15px;
				width:110px;
				float:left;
				height:90px;
				
				
				h4 {
					font-size: 14px;
					font-weight: 600;
					padding-bottom: 10px;
					color: #3f3635;
					margin:0;
					line-height:16px;
					a {
						color: #3f3635;
					}
					
				}
				p {
					font-style: italic;
					font-size:13px;
					line-height:16px;
				}
				
			}
			.btn-nowosci {
				height:20px;
				margin:0;
				width:110px;
				float: left;
				padding-left:15px;
			}
			.owl-dots {
				position: absolute;
				top:-60px;
				right:0;
			}
		}
	}
}

#wrap-aktualnosci {
	background: url('gfx/bg-wrap-akt.png') repeat-y top center;
	
	h2 {
	//	font-size: 20px; 
		margin-bottom:10px;
	}
	#bg-aktualnosci {
     //   background: url('gfx/bg-wrap-akt.png') repeat-y center center;
     //   overflow:hidden;
	}
	#kalendarz {
		background-color: @bg-color2;
		padding:25px 15px 30px 15px;
		
		p {
			margin-bottom:10px;
		}
		#lista-wydarzen {
		    padding:15px;
		    .col1 {
		        background-color: @theme-color;
		    }
		    .col2 {
		        background-color: @brown-color;
		    }
		    .linia {
		        border-bottom: 1px solid #d2cbcb;
		        padding: 10px 0;
		        margin:10px 0;
		    }
		    
		}
		.data {
		    color:#fff;
		    text-align:center;
		    font-size: 13px;
		    background: #594e4c;
		    -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
		    		    
		    span {
		        font-size:17px;
		        font-weight: bold;
		    }
		}
		.opis {
		    a {
		        color: @body-color;
		        text-decoration: underline!important;
		      }
		      a:hover {
		        color: @theme-color;
		        text-decoration: none!important;
		      }
		}
		#bg-godziny {
		    background-color: @bg-color3;    
		}
		
		.godz-title {
		    font-size: 16px;
		    font-style: italic;
		    font-weight:600;
		}
		.godz-box {
		    background-color: @bg-color1;
		    font-weight:600;
		    font-size: 14px;
		    padding:5px;
		    margin-bottom:5px;
		    
		    p {
		        text-transform: uppercase;
		        margin: 0;
		        line-height: 1.5;
		        span {
		            color: @theme-color;
		            font-weight:700;
		            font-size:16px;
		        }
		    }
		 }
	}
	#aktualnosci {
		background-color: @bg-color1;
		padding:25px 15px 30px 15px;
		
		.news {
			padding-bottom: 25px;
			img {
				padding: 8px;
				border:1px solid #d9d5d5;
				background:#fff;
			}
			h3 {
				font-weight:700;
				font-size: 14px;
				color: #594e4c;
				border-bottom: 1px solid #edeaea;
				line-height: 1.4;
				padding-bottom: 5px;
				margin-bottom: 7px;
				a {
					color: #594e4c;
					webkit-transition: all 0.2s ease-in-out;
                    -o-transition: all 0.2s ease-in-out;
                    transition: all 0.2s ease-in-out;
				}
				a:hover {
					color: @theme-color;
				}
			}
			p {
				margin-bottom: 15px;	
				line-height: 1.5;
			}
			.date {
				color: #858383;
				padding-left: 20px;
				font-style: italic;
			}
		}
		
	}
}

#cytat {
    background: #28232a url('gfx/bg-cytat.jpg') no-repeat center center;
    height:258px;
    color: #fff;
    
    p {
        padding:0;
        text-align: center;
        font-size: 30px;
        font-style: italic;
        margin: 0;
        line-height:1.4;
        
    }
    p.autor {
            font-size: 15px;
            font-style: normal;
            margin-top:20px;
    }
    #wrapp {
        display:table-cell;
        vertical-align:middle;
        height:258px;
        margin: 0 auto;
    }
}

#stopka {
  //  background: url('gfx/bg-stopka.png') no-repeat center center;
    color: #fff;
    
    h2 {
	   color: #fff;
	   margin-bottom: 15px;
	}
    
    #kontakt {
        background: url('gfx/bg-kontakt.png') repeat-x center center;
        padding-top: 30px;
        
        p {
            line-height:1.5;
        }
    }
    #nawigacja-polecamy {
        background: #594e4c url('gfx/bg-nawigacja.png') repeat-x bottom center;
        padding-top: 30px;
        
        ul {
            padding-left:20px;
        
            a {
                color: #fff;
            }
            a:hover {
                text-decoration: underline!important;
            }
        }
        img:hover {
            opacity: 0.8;
        }
    }
}
#loga {
    background-color: #edeaea;
    padding-top: 15px;
    padding-bottom: 15px;
}
#loga, #copyrights {

    img {
        -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
        filter: grayscale(100%);
        opacity:0.4;
        webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
    img:hover {
        -webkit-filter: grayscale(0%); /* Chrome, Safari, Opera */
        filter: grayscale(0%);
        opacity:1;
    }
}


/* ************** */


.ar {
	text-align:right;
}
.vac {
	display:table-cell;
    vertical-align:middle;
   width: auto!important;
}

/* ************** */



@media (max-width: 991px) {
//@media (max-width: 767px) {
//	#oferta, #katalogi-nowosci, #wrap-aktualnosci {
	#oferta, #wrap-aktualnosci, #katalogi-nowosci, #stopka {
		padding:0;
		.container {
			width: 94%!important;
		}
		.vac {
	//		width:430px!important;
		}
	}
}
@media (max-width: 767px) {
//@media (max-width: 767px) {
//	#oferta, #katalogi-nowosci, #wrap-aktualnosci {
	#oferta, #wrap-aktualnosci, #katalogi-nowosci, #stopka {
		.container {
		    width: 100%!important;
		    	
			#oferta1, 
			#oferta2, 
			#oferta3,
			#katalogi,
			#nowosci,
			#kalendarz,
			#aktualnosci,
			#kontakt,
			#nawigacja-polecamy,
			#loga
			{
			    padding-left: 10%;
			    padding-right:10%;
			}
		}
		.vac {
	//		width:430px!important;
		}
	}
}
@media (max-width: 479px) {
	#oferta {
		.vac {
	//		width:280px!important;
		}
	}
	#wrap-aktualnosci {
		#aktualnosci {
			.news {
				padding-bottom: 30px;
				
				.col-xs-4, .col-xs-8 {
					width:100%;
				}
				.col-xs-4{
					padding-bottom: 10px;
				}
			}
		}
	}
	
}
	

/* ************** */

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) { 
	#oferta {
		height: 269px;
		background: url('gfx/bg-oferta.png') no-repeat bottom center;
				
		#oferta1, #oferta2, #oferta3 {
			height:269px;
			width:33.33333333%;
		//	padding:0 35px;
		}
		
		.vac {
			height:255px;
			h2 {
			//	font-size: 22px;
				margin-bottom: 30px;
			}
		}
	}
	#katalogi-nowosci {
		#katalogi, #nowosci {
			height:290px;	
		}
	}
	#stopka {
        background: url('gfx/bg-stopka.png') no-repeat center center;
        height:334px;
        
        #kontakt {
            background: url('gfx/bg-kontakt.png') repeat-x center center;
            height:334px;
            padding-top: 30px;
        }
        #nawigacja-polecamy {
            background: url('gfx/bg-nawigacja.png') repeat-x center center;
            height:334px;
            padding-top: 30px;

        }
    }

	
}


/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) { 
	h2 {
			font-size: 22px!important; 
			margin-bottom:20px;
	}
		
	#info-top {
		color: #dedae8;
		font-size: 13px;
		position:absolute;
		top:40px;
		height:20px;
		width:100%;
		z-index:99;
		
		.top-padd {
			padding: 0 10px;	
		}
		i {
			padding-right:3px;
		}
	}
	#oferta {
		height: 269px;
		background: url('gfx/bg-oferta.png') no-repeat bottom center;
		
		#oferta1, #oferta2, #oferta3 {
			height:269px;
			padding:0 35px;
		}
		#oferta1 {
			padding-left:15px;
		}
	}
	#katalogi-nowosci {
		
		
		#katalogi {
		    padding-right:35px;
		}
		
		#nowosci {
			padding-left:35px; 
		}
	}
	#wrap-aktualnosci {
	    
	    h2 {
		//	font-size: 24px; 
			margin-bottom:20px;
		}
		#kalendarz {
		    padding-right:35px;
		}
	    
		#aktualnosci {
			padding-left:35px; 
			
			.news {
				h3 {
					font-size: 16px;
					line-height: 1.3;
				}
			}
		}
	}
	
	#stopka {
	    
	    
	    #kontakt {
	        padding-right:35px;
	        padding-top: 45px;
	    }
	    #nawigacja-polecamy {
	        padding:45px 15px 15px 35px;
	        ul {
	            margin-bottom: 57px;    
	        }
	        a img {
	            padding-top: 3px;
	        }
	    }
	}
	
}


/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	#info-top {
		font-size: 14px;
	}
}



/*  ***********  */


@media (max-width: 1199px) and (min-width: 992px) {
	#header.transparent-header.floating-header .container {
	    width: 970px!important;
	    padding: 0 15px!important;
	}
	#primary-menu ul li a {
		padding:0 10px;
	}
}