/*-----------------------------------------------------------------------------------

	variables.less

-----------------------------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Base Colors
-----------------------------------------------------------------*/

@theme-color:                   #e74c3c;
@brown-color:					#94262c;
@body-color:                    #594e4c;
@heading-color:                 #3f3635;

@bg-color1:						#fcfbfb;
@bg-color2:						#edeaea;
@bg-color3:						#d9d5d5;

@border-color:                  #e3e3e3;


/* ----------------------------------------------------------------
	Line Height Variables
-----------------------------------------------------------------*/

@line-height-base:              1.5;
@line-height-content:           1.8;



/* ----------------------------------------------------------------
	Margins
-----------------------------------------------------------------*/

@margin-base:                   50px;
@margin-small:                  30px;
@margin-big:                    80px;



/* ----------------------------------------------------------------
	Font Families
-----------------------------------------------------------------*/

@body-font:                     'Open Sans', sans-serif;
@heading-font:                  'Open Sans', sans-serif;
@secondary-font:                'Open Sans', sans-serif;



/* ----------------------------------------------------------------
	Font Sizes
-----------------------------------------------------------------*/

@font-size-base:                13px;
@font-size-h1:                  26px;
@font-size-h2:                  22px;
@font-size-h3:                  16px;
@font-size-h4:                  14px;
@font-size-h5:                  @font-size-base;
@font-size-h6:                  12px;


/* ----------------------------------------------------------------
	Layouts
-----------------------------------------------------------------*/


@postcontent:                   860px;
@postcontent-margin:            40px;

@sidebar:                       1140px - (@postcontent + @postcontent-margin);
@bothsidebar:                   1140px - ( ( @sidebar * 2 ) + ( @postcontent-margin * 2 ) );


@postcontent-md:				690px;
@postcontent-margin-md:			40px;

@sidebar-md:					940px - (@postcontent-md + @postcontent-margin-md);
@bothsidebar-md:				940px - ( ( @sidebar-md * 2 ) + ( @postcontent-margin-md * 2 ) );


@section-padding:				60px;
@section-margin:				60px;
@section-bg:					#F9F9F9;


/* ----------------------------------------------------------------
	Top Bar
-----------------------------------------------------------------*/

@topbar-height:                 45px;
@topbar-line-height:            (@topbar-height - 1);


/* ----------------------------------------------------------------
	Header
-----------------------------------------------------------------*/

@header-height:                 80px;

// Sticky Header
@sticky-header-height:          80px;


// Floating Header Offset
@header-floating-offset:        70px;


// Side Header
@side-header-width:             260px;
@side-header-bg:                #FFF;


/* ----------------------------------------------------------------
	Primary Menu
-----------------------------------------------------------------*/

@primary-menu-font:             	@heading-font;
@primary-menu-font-weight:      	normal;
@primary-menu-font-size:        	13px;
@primary-menu-font-tt:          	uppercase;
@primary-menu-font-color:       	#4b4b4b;
@primary-menu-font-spacing:     	0px;


// Primary Menu Sub Menu
@primary-menu-submenu-width:            220px;
@primary-menu-submenu-font:             @body-font;
@primary-menu-submenu-font-size:        12px;
@primary-menu-submenu-font-weight:      400;


// Primary Mega menu
@mega-menu-title:               @heading-font;



/* ----------------------------------------------------------------
	Page Title
-----------------------------------------------------------------*/


@page-title-padding:					50px;
@page-title-parallax-padding:			100px;
@page-title-mini-padding:				20px;

@page-title-bg:							#F5F5F5;
@page-title-bg-dark:					#333;

@page-title-size:						28px;
@page-title-subtitle-size:				18px;

@page-title-parallax-size:				40px;
@page-title-parallax-subtitle-size:		22px;

@page-title-mini-size:					18px;


/* ----------------------------------------------------------------
	Portfolio
-----------------------------------------------------------------*/


//Columns Margin
@portfolio-2-margin:            20px;
@portfolio-3-margin:            15px;
@portfolio-4-margin:            12px;
@portfolio-5-margin:            10px;
@portfolio-6-margin:            6px;


//Columns Margin for Sidebar
@portfolio-2-margin-sb:         20px;
@portfolio-3-margin-sb:         15px;
@portfolio-4-margin-sb:         12px;
@portfolio-5-margin-sb:         0;


//Columns Margin for Both Sidebar
@portfolio-2-margin-bs:         20px;
@portfolio-3-margin-bs:         11px;
@portfolio-4-margin-bs:         0;


/* ----------------------------------------------------------------
	Shop
-----------------------------------------------------------------*/

//Columns Margin
@shop-3-margin:            30px;
@shop-4-margin:            20px;


//Columns Margin for Sidebar
@shop-2-margin-sb:         40px;
@shop-3-margin-sb:         25px;


//Columns Margin for Both Sidebar
@shop-2-margin-bs:         40px;


/* ----------------------------------------------------------------
	Blog
-----------------------------------------------------------------*/

//Columns Margin
@blog-2-margin:            40px;
@blog-3-margin:            30px;
@blog-4-margin:            28px;


//Columns Margin for Sidebar
@blog-2-margin-sb:         40px;
@blog-3-margin-sb:         25px;


//Columns Margin for Both Sidebar
@blog-2-margin-bs:         40px;


//siatki bootstrapa

@screen-sm-min: 		768px;
@screen-md-min: 		992px;
@screen-lg-min: 		1200px;
