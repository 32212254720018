#linki-bread {
    font-size: 13px;
    color: #7f7978!important;
    padding-bottom:8px;
    margin-bottom:13px;
    border-bottom: 1px solid @border-color;
            
    a {
        color: #7f7978;
    }
    a:hover {
        text-decoration: underline!important;    
    }
    span {
        padding:0 6px;
    }    
}
#podstrona {     
    #aktualnosci {
        h2 {
            text-transform: none;
            line-height: 1.25;
        }
    }
    .dodano {
        font-size: 13px;
        color: #7f7978;
        font-style: italic;
        margin-top:-15px;
    }
    #art-tresc {
        padding:25px 0;
        p {
            margin-bottom: 10px;
        }
    }
    .fancy-title h3 {
        background-color: @bg-color1;
    }
    #bg-slider-sm {
        background-image: url('gfx/bg-top.png');
        background-position: center bottom;
        height:70px;
    }
}