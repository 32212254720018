/*-----------------------------------------------------------------------------------

	Shortcodes: toggles-accordions.less

-----------------------------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Toggle
-----------------------------------------------------------------*/

.toggle {
	display: block;
	position: relative;
	margin: 0 0 20px 0;
}

.toggle {
	.togglet,
	.toggleta {
		display: block;
		position: relative;
		line-height: 24px;
		padding: 0 0 0 24px;
		margin: 0;
		font-size: 16px;
		font-weight: 600;
		color: #444;
		cursor: pointer;
	}
	.togglet i {
		position: absolute;
		top: 0;
		left: 0;
		width: 16px;
		text-align: center;
		font-size: 16px;
		line-height: 24px;
	}
	.toggleta {
		font-weight: bold;
	}
}

.toggle {
	.togglet i.toggle-open,
	.toggleta i.toggle-closed {
		display: none;
	}

	.toggleta i.toggle-open {
		display: block;
	}

	.togglec {
		display: block;
		position: relative;
		padding: 10px 0 0 24px;
	}
}

/* Toggle - with Title Background
-----------------------------------------------------------------*/

.toggle {
	&.toggle-bg {
		.togglet,
		.toggleta {
			background-color: #EEE;
			line-height: 44px;
			padding: 0 0 0 36px;
			.border-radius(2px);
		}
		.togglet {
			i {
				left: 14px;
				line-height: 44px;
			}
		}
		.togglec {
			padding: 12px 0 0 36px;
		}
	}
}

/* Toggle - Bordered
-----------------------------------------------------------------*/

.toggle {
	&.toggle-border {
		border: 1px solid #CCC;
		.border-radius(4px);
		.togglet {
			i {
				left: 14px;
				line-height: 44px;
			}
		}
		.togglec {
			padding: 0 15px 15px 36px;
		}
		.togglet,
		.toggleta {
			line-height: 44px;
			padding: 0 15px 0 36px;
		}
	}
}


/* ----------------------------------------------------------------
	Accordions
-----------------------------------------------------------------*/

.accordion {
	margin-bottom: 20px;
}
.acctitle,
.acctitlec {
	display: block;
	position: relative;
	line-height: 24px;
	margin: 0;
	font-size: 14px;
	font-weight: bold;
	color: #444;
	cursor: pointer;
	border-top: 1px dotted #DDD;
	padding: 10px 0 10px 20px;
}
.acctitlec {
	cursor: auto;
}
.acctitle {
	&:first-child {
		border-top: none;
	}
	i {
		position: absolute;
		top: 0;
		left: 0;
		width: 14px;
		text-align: center;
		font-size: 14px;
		line-height: 44px;
	}
}
.acctitle i.acc-open,
.acctitlec i.acc-closed {
	display: none;
}
.acctitlec i.acc-open { display: block; }

.acc_content {
	position: relative;
	padding: 0 0 15px 20px;
}


/* Accordion - with Title Background
-----------------------------------------------------------------*/

.accordion {
	&.accordion-bg {
		.acctitle,
		.acctitlec {
			background-color: #EEE;
			line-height: 44px;
			padding: 0 0 0 36px;
			margin-bottom: 5px;
			border-top: 0;
		}
		.acctitle {
			i {
				left: 14px;
				line-height: 44px;
			}
		}
		.acc_content {
			padding: 10px 0 15px 36px;
		}
	}
}

/* Accordion - Bordered
-----------------------------------------------------------------*/

.accordion {
	&.accordion-border {
		border: 1px solid #DDD;
		.border-radius(4px);
		.acctitle,
		.acctitlec {
			border-color: #CCC;
			line-height: 44px;
			padding: 0 15px 0 36px;
		}
		.acctitle {
			i {
				left: 14px;
				line-height: 44px;
			}
		}
		.acc_content {
			padding: 0 15px 15px 36px;
		}
	}
}


/* Accordion - Large
-----------------------------------------------------------------*/

.accordion {
	&.accordion-lg {
		.acctitle {
			font-size: 23px;
			line-height: 44px;
			padding-left: 30px;
			font-weight: 400;
			i {
				width: 20px;
				font-size: 20px;
				line-height: 64px;
			}
		}
		.acc_content {
			padding: 10px 0 30px 30px;
		}
	}
}